<template>
  <div class="personal">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="margin-top: 30px;display: flex;">
      <div style="margin-right: 30px;">
        <personalChoose
          v-model="personalChoose"
          @change="handleChoose"
        />
      </div>
      <div style="flex: 1;"><router-view /></div>
    </div>
  </div>
</template>

<script>
import personalChoose from './components/personalChoose';
export default {
  components: {
    personalChoose
  },
  data () {
    return {
      personalChoose: '/personal/perfect'
    };
  },
  mounted () {
    this.handleRoute();
  },
  methods: {
    handleRoute () {
      this.personalChoose = this.$route.path;
    },
    handleChoose (data) {
      this.$router.replace({ path: data });
    }
  },
  watch: {
    $route: 'handleRoute'
  }
};
</script>

<style lang="less" scoped>
.personal {
  padding: 43px 0px;
  background-color: #fff;
}

</style>
